import React, {useCallback} from 'react';
import clsx from 'clsx';
import {
  Box,
  Menu,
  Paper,
  Table,
  Collapse,
  MenuItem,
  TableRow,
  TextField,
  TableBody,
  TableCell,
  MenuProps,
  IconButton,
  withStyles,
  TableContainer,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {format} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import {Edit, Delete, MoreHoriz, ExpandMore} from '@material-ui/icons';

import {useStyles} from '../../styles';
import {Modal} from 'shared/components';
import {
  ILeadCalls,
  LeadService,
  IIntegrationSituation,
} from 'shared/services/api/LeadService';
import {feedback} from 'shared/services/alertService';
import {errorResponse} from 'shared/utils/errorResponse';
import ErrorIcon from '@material-ui/icons/Error';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    minWidth: 125,
  },
})((props: MenuProps) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

interface IProps {
  idLead: string;
  item: ILeadCalls;
  canMeet: boolean;
  loading: boolean;
  idFunnel: string;
  canDelete: boolean;
  canIntegrate: boolean;
  deleteLead(idLead: string): void;
  editLead(idLead: string, idCampaign: string): void;
}

export const TblRow: React.FC<IProps> = ({
  item,
  idLead,
  canMeet,
  loading,
  editLead,
  idFunnel,
  canDelete,
  deleteLead,
  canIntegrate,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lead, setLead] = React.useState<ILeadCalls>(item);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [situation, setSituation] = React.useState<IIntegrationSituation>();

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const {noWrap, bodyAct, bodyLead, buttonProgress, expand, expandOpen} =
    useStyles();

  const handleClose = () => {
    document.documentElement.style.overflow = 'overlay';
    setModalOpen(false);
  };

  const handleClick = useCallback(() => setExpanded(!expanded), [expanded]);

  const handleIntegrationSituation = useCallback(async (id: string) => {
    setIsLoading(true);
    try {
      const response = await LeadService.getLeadIntegrate(id);

      if (response) {
        setSituation(response);
        setModalOpen(true);
        setAnchorEl(null);
      }
    } catch (error) {
      feedback(errorResponse(error), 'error');
      setSituation({} as IIntegrationSituation);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCollegeIntegrate = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const response = await LeadService.patchCollegeIntegrate(id);

        if (response.sucesso) {
          feedback(
            `${
              situation?.possuiIntegracao ? 'Reintegração' : 'Integração'
            } realizada com sucesso!`,
            'success',
          );
          setModalOpen(false);
        } else {
          feedback(
            `Ops.. tivemos um problema com sua ${
              situation?.possuiIntegracao ? 'reintegração' : 'integração'
            }.`,
            'warning',
          );
        }
      } catch (error) {
        feedback(errorResponse(error), 'error');
      } finally {
        setIsLoading(false);
      }
    },
    [situation?.possuiIntegracao],
  );

  return (
    <>
      <TableRow>
        <TableCell className={bodyAct}>
          <IconButton
            color="primary"
            disabled={!canDelete}
            onClick={() => deleteLead(item.idLead)}>
            <Delete />
            {loading && idLead === item.id && (
              <CircularProgress size={24} className={buttonProgress} />
            )}
          </IconButton>

          <IconButton
            color="primary"
            onClick={() =>
              navigate(
                `/editar-lead/${item.idLead}/${item.idCampanha}/${item.idOferta}`,
                { state: { funnelId: item.idFunil } }
              )
            }>
            <Edit />
            {loading && idLead === item.id && (
              <CircularProgress size={24} className={buttonProgress} />
            )}
          </IconButton>

          <IconButton
            color="primary"
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              setAnchorEl(event.currentTarget)
            }>
            <MoreHoriz />
          </IconButton>
          <StyledMenu
            anchorEl={anchorEl}
            open={open}
            variant="menu"
            onClose={() => setAnchorEl(null)}>
            <MenuItem
              disabled={!canMeet || isLoading}
              onClick={() => {
                document.documentElement.style.overflow = 'overlay';
                navigate(
                  `/lead/atender/${item.identificacaoLead}/${item.idLead}/${item.idOferta}`,
                  { state: { funnelId: item.idFunil } }
                );
              }}>
              Atender
            </MenuItem>
            <MenuItem
              disabled={!canIntegrate || isLoading}
              onClick={() => {
                handleIntegrationSituation(item.idLead);
                setLead(item);
              }}>
              {isLoading && (
                <CircularProgress size={24} className={buttonProgress} />
              )}
              Integrar
            </MenuItem>
          </StyledMenu>
        </TableCell>

        <TableCell className={bodyLead}>
          {item.vendaPerdida ? (
            <>
              <Tooltip placement="right-end" title="Venda Perdida">
                <IconButton>
                  <ErrorIcon fontSize="small" color="error" />
                </IconButton>
              </Tooltip>

              <Typography>{item.identificacaoLead || ''}</Typography>
            </>
          ) : (
            <>
              <Typography>{item.identificacaoLead || ''}</Typography>
            </>
          )}
        </TableCell>

        <TableCell className={noWrap}>{item.telefoneLead}</TableCell>
        <TableCell className={noWrap}>{item.nomeOferta}</TableCell>
        <TableCell className={noWrap}>{item.nomeEstabelecimento}</TableCell>
        <TableCell className={noWrap}>{item.dataAgenda}</TableCell>
        <TableCell className={noWrap}>{item.nomeSituacaoDoLead}</TableCell>
        <TableCell className={noWrap}>{item.dataInscricao}</TableCell>
        <TableCell className={noWrap}>{item.nomeCampanha}</TableCell>
        <TableCell className={noWrap}>{item.candidatoLead}</TableCell>
      </TableRow>

      <Modal
        maxWidth="md"
        opened={modalOpen}
        loading={isLoading}
        onClose={handleClose}
        labelCloseButton="Fechar"
        labelSaveButton={`${
          situation?.possuiIntegracao ? 'Reintegrar' : 'Integrar'
        }`}
        onClick={() => handleCollegeIntegrate(lead.idLead)}
        title={`Integrar ${lead.identificacaoLead} com GVCollege`}>
        <Box marginTop={3} />
        <TextField
          fullWidth
          label="Status"
          variant="outlined"
          disabled={isLoading}
          value={situation?.msgStatus}
        />

        <Box marginTop={2} />

        <TextField
          rows={6}
          fullWidth
          multiline
          label="Mensagem"
          variant="outlined"
          disabled={isLoading}
          value={situation?.msgDetalhada}
        />

        <Box marginTop={2} />

        {!!situation?.logs.length && (
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow onClick={handleClick}>
                  <TableCell>Detalhes do log de processamento</TableCell>
                  <TableCell align="right">
                    <IconButton
                      className={clsx(expand, {
                        [expandOpen]: expanded,
                      })}
                      onClick={handleClick}>
                      <ExpandMore />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {situation?.logs.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      {format(new Date(item.incluidoEm), 'dd/MM/yyyy')}
                    </TableCell>
                    <TableCell>
                      {format(new Date(item.incluidoEm), 'HH:mm')}
                    </TableCell>
                    <TableCell>{item.nomeStatus}</TableCell>
                    <TableCell>{item.mensagem}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Modal>
    </>
  );
};
