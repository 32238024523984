export const EmailMacros = [
  {title: 'AgendaData', value: 'AgendaData'},
  {title: 'AgendaHora', value: 'AgendaHora'},
  {title: 'AgendaEntrevistador', value: 'AgendaEntrevistador'},
  {title: 'CampanhaAno', value: 'CampanhaAno'},
  {title: 'CampanhaPeriodoLetivo', value: 'CampanhaPeriodoLetivo'},
  {title: 'LeadIdentificacao', value: 'LeadIdentificacao'},
  {title: 'OfertaCursoNome', value: 'OfertaCursoNome'},
  {title: 'OfertaCicloNome', value: 'OfertaCicloNome'},
  {title: 'OfertaTurnoNome', value: 'OfertaTurnoNome'},
  {title: 'OfertaEstabNome', value: 'OfertaEstabNome'},
  {title: 'OfertaEstabCNPJ', value: 'OfertaEstabCNPJ'},
  {title: 'OfertaEstabEmail', value: 'OfertaEstabEmail'},
  {title: 'OfertaEstabTelefone1', value: 'OfertaEstabTelefone1'},
  {title: 'OfertaEstabTelefone2', value: 'OfertaEstabTelefone2'},
  {title: 'OfertaEstabSite', value: 'OfertaEstabSite'},
  {title: 'OfertaEstabEndereco', value: 'OfertaEstabEndereco'},
  {title: 'OfertaEstabCidade', value: 'OfertaEstabCidade'},
  {title: 'OfertaEstabUF', value: 'OfertaEstabUF'},
  {title: 'OfertaEstabBairro', value: 'OfertaEstabBairro'},
  {title: 'OfertaEstabCep', value: 'OfertaEstabCep'},
  {title: 'OfertaEstabCaixaPostal', value: 'OfertaEstabCaixaPostal'},
  {title: 'LeadCandidato', value: 'LeadCandidato'},
  {title: 'AssuntoEmail', value: 'AssuntoEmail'},
  {title: 'Destinatario', value: 'Destinatario'},
  {title: 'FormaPagamento', value: 'FormaPagamento'},
  {title: 'LinkPagamento', value: 'LinkPagamento'},
  {title: 'MensagemEmail', value: 'MensagemEmail'},
  {title: 'ValorPago', value: 'ValorPago'},
  {title: 'LinkFormularioSecundario', value: 'LinkFormularioSecundario}'},
];
