import {
  Box,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  CRMDashboardService,
  ICallsConversions,
} from 'shared/services/api/CRMDashboard';

import {
  DataGrid,
  GridColDef,
  GridColumnsPanel,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

interface IProps {
  openModal: boolean;
  onclose(): void;
  selectedPeriod: string;
  selectedEstablishment: string;
  selectedCampaing: string;
}

export const CallsConversions: React.FC<IProps> = ({
  openModal,
  onclose,
  selectedPeriod,
  selectedEstablishment,
  selectedCampaing,
}) => {
  const [loading, setLoading] = useState(true);
  const [callsConversions, setCallsConversions] = useState<ICallsConversions[]>(
    [],
  );

  const callsConversionsList = useCallback(async () => {
    setLoading(true);
    CRMDashboardService.getCallsConversions(
      selectedPeriod,
      selectedEstablishment,
      selectedCampaing,
    )
      .then((response) => {
        setCallsConversions(response);
      })
      .finally(() => setLoading(false));
  }, [selectedPeriod, selectedEstablishment, selectedCampaing]);

  const columns: GridColDef[] = [
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        `${String(params.row.data)
          .substring(0, 10)
          .split('-')
          .reverse()
          .join('/')}`,
    },
    {
      field: 'lead',
      headerName: 'Lead',
      flex: 1,
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      flex: 1,
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openModal}
        onClose={onclose}
        onEntering={callsConversionsList}
        aria-labelledby="Atendimentos e Conversões"
        aria-describedby="Atendimentos e Conversões">
        <DialogTitle id="Atendimentos e Conversões">
          <Box width="100%" display={'flex'} justifyContent="space-between">
            <Box>
              <Typography variant="inherit" color="primary">
                Atendimentos e Conversões
              </Typography>
            </Box>
            <Box>
              <Button color="primary" onClick={onclose}>
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            loading={loading}
            columns={columns}
            rows={callsConversions}
            getRowId={(row) => row.id}
            autoHeight
            pageSize={7}
            components={{
              Toolbar: CustomToolbar,
              ColumnsPanel: GridColumnsPanel,
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
