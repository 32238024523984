import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface ICampaign {
  ativo: boolean;
  ano: number;
  possuiTaxa: boolean;
  id: string;
  idEstabelecimentoDono: string;
  nivelEstabelecimentoDono: number;
  idPeriodoLetivo: string;
  idSituacaoDeCampanha: number;
  idTipoDeIntegracao: number;
  idFunil: string;
  nome: string;
  nomeCompleto: string;
  nomeEstabelecimentoDono: string;
  nomeSituacaoDeCampanha: string;
  nomeTipoDeIntegracao: string;
  nomeSituacaoTaxa: string;
  permiteEditar: boolean;
  possuiEquivalenciaIntegracaoOferta: boolean;
  usaAgendamento: boolean;
  matriculaConfirmada: boolean;
  vigenciaInicial: Date;
  vigenciaFinal: Date;
  idMetricaGoogleAnalytics: string;
  possuiTermoLgpd: boolean;
  textoTermoLgpd: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
  possuiEnvioFormularioSecundario: boolean;
}

interface ICampaignWithPagination {
  data: ICampaign[];
  qtdTotalDeRegistros: number;
}

export interface IPostCampaign {
  nome: string;
  vigenciaInicial: Date;
  vigenciaFinal: Date;
  idEstabelecimentoDono: string;
  nivelEstabelecimentoDono: number;
  idSituacaoDeCampanha: number;
  idTipoDeIntegracao: number;
  ano: number;
  idPeriodoLetivo: string;
  usaAgendamento: boolean;
  ativo: boolean;
  possuiTaxa?: boolean;
  matriculaConfirmada: boolean;
  idMetricaGoogleAnalytics: string;
  disponibilizarTermoDeAceiteParaLead: boolean;
}

export interface IConfigEmail {
  assuntoEmailParaTerminoInscricao: string;
  mensagemEmailParaTerminoInscricao: string;
  assuntoEmailParaPagamentoTaxa: string;
  mensagemEmailParaPagamentoTaxa: string;
  assuntoEmailParaConfirmacaoPagamentoTaxa: string;
  mensagemEmailParaConfirmacaoPagamentoTaxa: string;
  assuntoEmailParaLinkFormularioSecundario: string;
  mensagemEmailParaLinkFormularioSecundario: string;
  assuntoEmailParaMudancaAgenda: string;
  mensagemEmailParaMudancaAgenda: string;
  assuntoEmailParaProximidadeAgenda: string;
  mensagemEmailParaProximidadeAgenda: string;
  qtdeDiasEnvioEmailParaProximidadeAgenda: number;
  assuntoEmailParaConflitoAgenda: string;
  mensagemEmailParaConflitoAgenda: string;
  listaEmailEnvioParaConflitoAgenda: string;
  enviarEmailTaxaNaInscricao: boolean;
}

export interface IListCampaign {
  id: string;
  nomeCampanha: string;
  dataVigenciaInicial: Date;
  dataVigenciaFinal: Date;
  vigente: string;
}

export interface ISituationCampaign {
  id: string;
  nome: string;
}

export interface IFormConfig {
  idCampanha: string;
  instrucaoParaCabecalhoFormulario: string;
  instrucaoParaRodapeFormulario: string;
  mensagemDeConclusaoFormulario: string;
  logotipoParaCabecalhoFormulario: string;
  extensaoLogotipo: string;
  bannerParaCabecalhoFormulario: string;
  extensaoBanner: string;
  corBotao: string;
}

export interface IListFieldsIntegration {
  idCampoDaFicha: string;
  idCampoDeIntegracao: string;
  idSistemaExterno: number | null;
  nomeCampoDeIntegracao: string;
}

export interface IPostIntegration {
  idCampanha: string;
  listaDeCampos: IListFieldsIntegration[];
}

export interface ICollegeIntegration {
  id: string;
  nome: string;
  obrigatorio: boolean;
  idSistemaExterno: number | null;
  identificacaoNoSistemaExterno: string | null;
  idTipoDeListaDoCampoDeIntegracao: number;
}

export interface IEquivalence {
  id: string;
  idCampoDeIntegracao: string;
  nome: string;
  obrigatorio: boolean;
  idSistemaExterno: number | null;
  identificacaoNoSistemaExterno: string | null;
  idTipoDeListaDoCampoDeIntegracao: number;
}

export interface ICampaignIntegration {
  idCampo: string;
  nomeCampo: string;
  ordemCampo: number;
  idSecao: string;
  nomeSecao: string;
  ordemSecao: number;
  equivalencias: IEquivalence[];
}

interface IGetCampaigns {
  usaAgendamento?: boolean;
  IdTipoIntegracao?: number;
  idPermissao?: string | null;
  idEstabelecimentoDaOferta?: string;
}

interface IGetCampaign {
  id: string;
  verificarPermissaoEditar?: boolean;
}

const getCollegeIntegration = async (): Promise<ICollegeIntegration[]> => {
  try {
    const response = await Api.get(
      '/Campanha/Integracao/CamposDeIntegracaoGvCollegeInscreverAluno',
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getCampaignIntegration = async (
  id: string,
): Promise<ICampaignIntegration[]> => {
  try {
    const response = await Api.get(`/Campanha/Integracao/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postCampaignIntegration = async (
  payload: IPostIntegration,
): Promise<IRequestResult> => {
  try {
    await Api.post('/Campanha/Integracao/Campos', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getFormConfigCampaign = async (id: string): Promise<IFormConfig> => {
  try {
    const response = await Api.get(`/Campanha/configuracaoformulario/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getListCampaign = async (): Promise<IListCampaign[]> => {
  try {
    const response = await Api.get('/Campanha/listagem');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const putFormConfigCampaign = async (
  id: string,
  payload: IFormConfig,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Campanha/configuracaoformulario/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getCampaigns = async (payload: IGetCampaigns): Promise<ICampaign[]> => {
  try {
    const {data} = await Api.get(
      `/Campanha?IdEstabelecimentoDaOferta=${
        payload.idEstabelecimentoDaOferta
          ? payload.idEstabelecimentoDaOferta
          : ''
      }&UsaAgendamento=${
        payload.usaAgendamento
          ? true
          : payload.usaAgendamento === false
          ? false
          : ''
      }&IdTipoIntegracao=${
        payload.IdTipoIntegracao ? payload.IdTipoIntegracao : ''
      }&IdPermissao=${payload.idPermissao ? payload.idPermissao : null}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getCampaignsWithPagination = async (
  take: number,
  skip: number,
  payload: IGetCampaigns,
): Promise<ICampaignWithPagination> => {
  try {
    const {data} = await Api.get(
      `/Campanha/paginado?IdEstabelecimentoDaOferta=${
        payload.idEstabelecimentoDaOferta
          ? payload.idEstabelecimentoDaOferta
          : ''
      }&UsaAgendamento=${
        payload.usaAgendamento
          ? true
          : payload.usaAgendamento === false
          ? false
          : ''
      }&IdTipoIntegracao=${
        payload.IdTipoIntegracao ? payload.IdTipoIntegracao : ''
      }&IdPermissao=${
        payload.idPermissao ? payload.idPermissao : null
      }&take=${take}&skip=${skip}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getCampaign = async (payload: IGetCampaign): Promise<ICampaign> => {
  try {
    const response = await Api.get(
      `/Campanha/${payload.id}?verificarPermissaoEditar=${
        payload.verificarPermissaoEditar
          ? payload.verificarPermissaoEditar
          : null
      }`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postCampaignGeneralData = async (
  payload: IPostCampaign,
): Promise<{idCampanha: string}> => {
  try {
    const response = await Api.post('/Campanha/dadosgerais', payload);

    return response.data;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const putCampaignGeneralData = async (
  id: string,
  payload: IPostCampaign,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Campanha/dadosgerais/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteCampaign = async (id: string): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Campanha/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getSituationOfCampaign = async (): Promise<ISituationCampaign[]> => {
  try {
    const response = await Api.get('/SituacaoDaCampanha');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getConfigEmails = async (id: string): Promise<IConfigEmail> => {
  try {
    const response = await Api.get(`/Campanha/configuracaoemail/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postConfigEmails = async (
  id: string,
  body: IConfigEmail,
): Promise<IConfigEmail> => {
  try {
    const response = await Api.post(`/Campanha/configuracaoemail/${id}`, body);

    return response.data;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const putConfigEmails = async (
  id: string,
  body: IConfigEmail,
): Promise<IConfigEmail> => {
  try {
    const response = await Api.put(`/Campanha/configuracaoemail/${id}`, body);

    return response.data;
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const postCampaignDuplicate = async (id: string): Promise<string> => {
  try {
    const {data} = await Api.post(`/Campanha/Duplicar/${id}`);

    return data.idCampanha;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const patchFunnelToCampaign = async (
  funnelId: string,
  id: string,
): Promise<IRequestResult> => {
  try {
    await Api.patch(`/Campanha/${id}/Funil`, {
      idFunil: funnelId,
    });
    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

export const CampaignService = {
  postCampaignGeneralData,
  putCampaignGeneralData,
  getSituationOfCampaign,
  getCampaigns,
  getCampaignsWithPagination,
  getCampaign,
  deleteCampaign,
  getListCampaign,
  getConfigEmails,
  postConfigEmails,
  putConfigEmails,
  getFormConfigCampaign,
  putFormConfigCampaign,
  getCollegeIntegration,
  getCampaignIntegration,
  postCampaignIntegration,
  postCampaignDuplicate,
  patchFunnelToCampaign,
};
