import {IRequestResult} from 'shared/interfaces/IRequestResult';
import {Api} from 'shared/services/axiosConfig';

export interface IFormatText {
  id: string;
  nomeInterno: 'uppercase' | 'lowercase' | 'titlecase' | 'sentensecase';
  descricao: string;
}

export interface IOrderSection {
  ordem: number;
  idSecao: string;
}

export interface IOrderSectionUpdate {
  items: IOrderSection[];
}

export interface IOrderField {
  ordem: number;
  idCampo: string;
}

export interface IOrderFieldUpdate {
  items: IOrderField[];
}

export interface IOptionField {
  nome: string;
  codigoParaIntegracao?: string;
  ordem: number;
}

export interface IValidationField {
  idCondicaoParaValidacao: number;
  validacaoInicial: string;
  validacaoFinal: string;
  mensagem: string;
}

export interface IValidationFields {
  id: number;
  idCondicaoParaValidacao: number;
  nomeCondicaoParaValidacao: string | null;
  validacaoInicial: string;
  validacaoFinal: string;
  mensagem: string;
}

export interface ITypeField {
  id: number;
  nome: string;
}

export interface IFormFields {
  idCampo: string;
  nomeCampo: string;
  idTipoDeCampo: number;
  nomeTipoDeCampo: string;
  idCampoPredefinido: number;
  nomeCampoPredefinido: string;
  textoDeAjuda: string | null;
  obrigatorio: boolean;
  disponivelParaLead: boolean;
  usaMascara: boolean;
  idTipoMascara: number | null;
  mascara: string;
  ativo: boolean;
  opcoes: IOptionField[];
  validacoes: IValidationFields[];
  tiposDeCampoRestrito: ITypeField[];
  ordem: number;
  obrigatorioCampoPredefinido: boolean;
  idFormatoTexto?: number;
}

export interface IFormSection {
  idSecao: string;
  nomeSecao: string;
  idSecaoPredefinida: number;
  nomeSecaoPredefinida: string;
  apresentarNoFormulario: boolean;
  maximoColunas: number;
  ordem: number;
  campos: IFormFields[];
  ehFichaComplementar?: boolean;
  idSituacaoLead: string;
  situacaoLeadEnvioForm: string;
  descricao: string;
}

export interface IResposeSection {
  campanhaPossuiInscritos: boolean;
  campanhaUsaAgendamento: boolean;
  secoes: IFormSection[];
}

export interface IFormSectionPost {
  idCampanha: string;
  idSecaoPredefinida: number | null;
  nome: string;
  apresentarNoFormulario: boolean;
  maximoDeColunas: number;
  ehFichaComplementar: boolean;
  idSituacaoLead: string | null;
  descricao: string;
}

export interface IFormSectionPut {
  idCampanha: string;
  idSecaoPredefinida: number | null;
  nome: string;
  apresentarNoFormulario: boolean;
  maximoDeColunas: number;
  ordem: number;
  ehFichaComplementar?: boolean;
  idSituacaoLead: string | null;
  descricao: string;
}

export interface ITokenField {
  idSecaoDaFicha: string;
  idTipoDeCampo: number;
  idCampoPredefinido: number | null;
  nome: string;
  usaMascara: boolean;
  idTipoMascara: number;
  textoDeAjuda: string;
  obrigatorio: boolean;
  disponivelParaLead: boolean;
  mascara: string;
  ativo: boolean;
  opcoes: IOptionField[];
  validacoes: IValidationField[];
  tiposDeCampoRestrito: ITypeField[];
  ordem?: number;
  obrigatorioCampoPredefinido?: boolean;
  idFormatoTexto?: number;
}

export interface IDataLead {
  usuario: string;
  idCampanha: string;
  idCampoIdentifLead: string;
  idCampoEmailLead: string;
  idCampoTelefoneLead: string;
}

export interface IPredefinedSection {
  id: number;
  nome: string;
  ehFichaComplementar: boolean;
}

export interface ITypeMask {
  id: number;
  nome: string;
  mascara: string | null;
}

export interface IValidationCondition {
  id: number;
  condicao: string;
  classificacao: string;
  descricaoConfiguracaoDoInput: string;
  idConfiguracaoDoInputDoCampoFinal: number | null;
  idConfiguracaoDoInputDoCampoInicial: number | null;
}

export interface IValidationGroup {
  idTipoDeCampoPermiteValidacao: number;
  descricaoTipoDeCampoPermiteValidacao: string;
  condicoesParaValidacao: IValidationCondition[];
}

export interface IFormIntegration {
  id: string;
  idCampanha: string;
  nomeSecaoDaFicha: string;
  nomeTipoDeCampo: string;
}

export interface ILeadData {
  idCampanha: string;
  idCampoIdentifLead: string;
  idCampoEmailLead: string;
  idCampoTelefoneLead: string;
  idCampoCandidatoLead: string;
  idCampoNomeDevedorTaxaLead: string;
  idCampoDocumentoDevedorTaxaLead: string;
}

export interface IFieldOfCampaign {
  idCampo: number;
  nomeCampo: string;
  ordem: number;
  idSecao: number;
  nomeSecao: string;
  ordemSecao: number;
  nomeSecaoCampo: string;
}

export interface ISituacaoFicha {
  id: string;
  nome: string;
}

const getTextFormatsOfCampaign = async (): Promise<IFormatText[]> => {
  try {
    const response = await Api.get('/Campanha/fichadeinscricao/formatodetexto');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getFieldsOfCampaign = async (
  id: number | string,
): Promise<IFieldOfCampaign[]> => {
  try {
    const response = await Api.get(
      `/Campanha/fichadeinscricao/camposdaficha/${id}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getLeadData = async (id: string): Promise<ILeadData> => {
  try {
    const response = await Api.get(
      `/Campanha/fichadeinscricao/dadoslead/${id}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const patchLeadData = async (
  id: number | string,
  payload: ILeadData,
): Promise<IRequestResult> => {
  try {
    await Api.patch(`/Campanha/fichadeinscricao/dadoslead/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postFormSection = async (
  payload: IFormSectionPost,
): Promise<IRequestResult> => {
  try {
    await Api.post('/Campanha/fichadeinscricao/secaodaficha', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const updateFormSection = async (
  id: string | number,
  payload: IFormSectionPut,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Campanha/fichadeinscricao/secaodaficha/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteFormSection = async (
  id: string | number,
): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Campanha/fichadeinscricao/secaodaficha/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const postTokenField = async (
  payload: ITokenField,
): Promise<IRequestResult> => {
  try {
    await Api.post('/Campanha/fichadeinscricao/campodaficha', payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const updateTokenField = async (
  id: string | number,
  payload: ITokenField,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Campanha/fichadeinscricao/campodaficha/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const deleteTokenField = async (
  id: string | number,
): Promise<IRequestResult> => {
  try {
    await Api.delete(`/Campanha/fichadeinscricao/campodaficha/${id}`);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateDataLead = async (
  id: string,
  payload: IDataLead,
): Promise<IRequestResult> => {
  try {
    await Api.put(`/Campanha/fichadeinscricao/dadoslead/${id}`, payload);

    return {success: true};
  } catch (error: any) {
    throw new Error(error.data.data);
  }
};

const getPredefinedSection = async (): Promise<IPredefinedSection[]> => {
  try {
    const response = await Api.get(
      '/Campanha/fichadeinscricao/secoespredefinidas',
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getTypeField = async (): Promise<ITypeField[]> => {
  try {
    const response = await Api.get('/Campanha/fichadeinscricao/tiposdecampo');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getTypeMask = async (): Promise<ITypeMask[]> => {
  try {
    const response = await Api.get('/Campanha/fichadeinscricao/tiposdemascara');

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getValidationCondition = async (): Promise<IValidationGroup[]> => {
  try {
    const {data} = await Api.get(
      `/Campanha/fichadeinscricao/condicoesparavalidacao`,
    );

    return data.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getFormSections = async (id: string): Promise<IResposeSection> => {
  try {
    const response = await Api.get(`/Campanha/fichadeinscricao/secoes/${id}`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getSituacoesFicha = async (
  id: string | undefined,
): Promise<ISituacaoFicha[]> => {
  try {
    const response = await Api.get(
      `/Campanha/fichadeinscricao/situacoesfunil/${id}`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateOrderSection = async (
  payload: IOrderSectionUpdate,
): Promise<IRequestResult> => {
  try {
    await Api.patch(
      '/Campanha/fichadeinscricao/secaodaficha/ajustarordenacao',
      payload,
    );

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const updateOrderField = async (
  payload: IOrderFieldUpdate,
): Promise<IRequestResult> => {
  try {
    await Api.patch(
      '/Campanha/fichadeinscricao/campodaficha/ajustarordenacao',
      payload,
    );

    return {success: true};
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getFormIntegration = async (
  idCampanha: string,
): Promise<IFormIntegration[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve([
        {
          id: '1.1',
          idCampanha: idCampanha,
          nomeSecaoDaFicha: 'Ficha 01',
          nomeTipoDeCampo: 'Tipo 01',
        },
        {
          id: '1.2',
          idCampanha: idCampanha,
          nomeSecaoDaFicha: 'Ficha 01',
          nomeTipoDeCampo: 'Tipo 02',
        },
        {
          id: '1.3',
          idCampanha: idCampanha,
          nomeSecaoDaFicha: 'Ficha 01',
          nomeTipoDeCampo: 'Tipo 03',
        },
        {
          id: '2.1',
          idCampanha: idCampanha,
          nomeSecaoDaFicha: 'Ficha 02',
          nomeTipoDeCampo: 'Tipo 01',
        },
        {
          id: '2.2',
          idCampanha: idCampanha,
          nomeSecaoDaFicha: 'Ficha 02',
          nomeTipoDeCampo: 'Tipo 02',
        },
      ]);
    });
  });
};

export const FormService = {
  getSituacoesFicha,
  postFormSection,
  updateFormSection,
  deleteFormSection,
  postTokenField,
  updateTokenField,
  deleteTokenField,
  updateDataLead,
  getValidationCondition,
  getPredefinedSection,
  getFormSections,
  getTypeField,
  getTypeMask,
  updateOrderSection,
  updateOrderField,
  getLeadData,
  patchLeadData,
  getFieldsOfCampaign,
  getFormIntegration,
  getTextFormatsOfCampaign,
};
