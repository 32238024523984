/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback, useEffect, useState} from 'react';
import {
  Grid,
  MenuItem,
  useTheme,
  TextField,
  ListSubheader,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {format} from 'date-fns';
import {DatePicker} from '@material-ui/pickers';

import {useTerm} from 'shared/hooks';
import {useStyles} from '../../styles';
import {DrawerFilter as Drawer} from 'shared/components';
import {ICampaign} from 'shared/services/api/CampaignService';
import {ILead} from 'shared/services/api/LeadSituationService';
import {IAdmUser} from 'shared/services/api/InterviewerService';
import {ICourse, ICycle} from 'shared/services/api/CourseService';
import {ILeadCallsFilters} from 'shared/services/api/LeadService';
import {IUnityEstablishment} from 'shared/services/api/EstablishmentService';
import {
  IUserProfile,
  IUserProfilePagination,
} from 'shared/services/api/UserProfileService';

interface IProps {
  // leads: ILead[];
  loading: boolean;
  course: ICourse[];
  countFilter: number;
  canRegister: boolean;
  attendants: IUserProfile[];
  campaigns: ICampaign[];
  establishments: Omit<IUnityEstablishment[], 'unidades'>;
  interviewers: IAdmUser[];
  openDrawerFilter: boolean;
  toFilters: ILeadCallsFilters;
  setCleanFilter(): void;
  setToFilters(value: ILeadCallsFilters): void;
  setOpenDrawerFilter(value: boolean): void;
}

export const DrawerFilter: React.FC<IProps> = ({
  // leads,
  course,
  loading,
  campaigns,
  toFilters,
  attendants,
  countFilter,
  interviewers,
  setToFilters,
  establishments,
  setCleanFilter,
  openDrawerFilter,
  setOpenDrawerFilter,
}) => {
  const [filters, setFilters] = useState<ILeadCallsFilters>(
    toFilters as ILeadCallsFilters,
  );
  const [cycles, setCycles] = useState<ICycle[]>([]);
  const theme = useTheme();
  const {terms} = useTerm();
  const {label, pt0, middleLabel} = useStyles();

  const handleFilter = useCallback(() => {
    setToFilters(filters);
  }, [filters, setToFilters]);

  const handleCycleFiltered = useCallback(() => {
    const idCurso = String(filters.idCurso).split(',');

    if (idCurso.length === 1) {
      course.map(
        (item) => item.id === idCurso.join() && setCycles(item.ciclos),
      );
    } else {
      setCycles([]);
    }
  }, [course, filters.idCurso]);

  useEffect(() => {
    handleCycleFiltered();
  }, [handleCycleFiltered]);

  useEffect(() => {
    setFilters(toFilters);
  }, [toFilters]);

  return (
    <Drawer
      loading={loading}
      open={openDrawerFilter}
      countFilter={countFilter}
      handleFilter={handleFilter}
      setOpen={setOpenDrawerFilter}
      setCleanFilter={setCleanFilter}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            disabled={loading}
            label="Estabelecimento"
            value={filters.idEstabelecimento}
            onChange={({target}) => {
              setFilters({
                ...filters,
                idEstabelecimento: target.value,
                idCampanha: 0,
              });
            }}>
            <MenuItem value={0}>Todos</MenuItem>
            {establishments?.map((item) => {
              if (item.idEstabelecimentoPai) {
                return (
                  <MenuItem
                    key={item.idEstabelecimento}
                    value={item.idEstabelecimento}
                    style={{paddingLeft: theme.spacing(3)}}>
                    {item.nome}
                  </MenuItem>
                );
              } else {
                return (
                  <ListSubheader key={item.idEstabelecimento}>
                    {item.nome}
                  </ListSubheader>
                );
              }
            })}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            label="Campanha"
            variant="outlined"
            disabled={loading}
            value={filters.idCampanha}
            onChange={({target}) => {
              setFilters({
                ...filters,
                idCampanha: target.value,
              });
            }}>
            <MenuItem value={0}>Todas</MenuItem>
            {campaigns?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nomeCompleto}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        
      {/* 
        Refatorar esse filtro colocando um novo select antes, para filtear o Funil
        e então liberar o select das etapas do funil
      */}
        {/* <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            disabled={loading}
            label={`Situação ${terms.termoLead}`}
            SelectProps={{
              multiple: true,
              value:
                filters.idSituacaoDoLead === 0
                  ? [0]
                  : filters.idSituacaoDoLead.split(','),
              onChange: (event: any, child: any) => {
                setFilters({
                  ...filters,
                  idSituacaoDoLead:
                    child.props.value === 0
                      ? 0
                      : event.target.value
                          .filter((i: number) => i !== 0)
                          .join(),
                });
              },
            }}>
            <MenuItem value={0}>Todos</MenuItem>
            {leads?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            disabled={loading}
            label={terms.termoCurso}
            SelectProps={{
              multiple: true,
              value: filters.idCurso === 0 ? [0] : filters.idCurso.split(','),
              onChange: (event: any, child: any) => {
                setFilters({
                  ...filters,
                  idCurso:
                    child.props.value === 0
                      ? 0
                      : event.target.value
                          .filter((i: number) => i !== 0)
                          .join(),
                  idCiclo: 0,
                });
              },
            }}>
            <MenuItem value={0}>Todos</MenuItem>
            {course?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            label={terms.termoCiclo}
            variant="outlined"
            disabled={
              loading ||
              filters.idCurso === 0 ||
              String(filters.idCurso).split(',').length > 1
            }
            SelectProps={{
              multiple: true,
              value: filters.idCiclo === 0 ? [0] : filters.idCiclo.split(','),
              onChange: (event: any, child: any) => {
                setFilters({
                  ...filters,
                  idCiclo:
                    child.props.value === 0
                      ? 0
                      : event.target.value
                          .filter((i: number) => i !== 0)
                          .join(),
                });
              },
            }}>
            <MenuItem value={0}>Todos</MenuItem>
            {cycles.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            label="Atendente"
            variant="outlined"
            disabled={loading}
            value={filters.idUsuarioAtendente}
            onChange={({target}) => {
              setFilters({
                ...filters,
                idUsuarioAtendente: target.value,
              });
            }}>
            <MenuItem value={0}>Todos</MenuItem>
            {attendants?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            disabled={loading}
            label={`${terms.termoEntrevistador}`}
            value={filters.idUsuarioEntrevistador}
            onChange={({target}) => {
              setFilters({
                ...filters,
                idUsuarioEntrevistador: target.value,
              });
            }}>
            <MenuItem value={0}>Todos</MenuItem>
            {interviewers?.map((item) => (
              <MenuItem key={item.idUsuarioAuth} value={item.idUsuarioAuth}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} className={label}>
          Período de inscrição
        </Grid>
        <Grid item xs className={pt0}>
          <DatePicker
            autoOk
            fullWidth
            size="small"
            disabled={loading}
            format="dd/MM/yyyy"
            animateYearScrolling
            inputVariant="outlined"
            value={
              filters.inscricaoIniciaEm
                ? format(
                    new Date(filters.inscricaoIniciaEm),
                    "yyyy-MM-dd'T'00:00:01",
                  )
                : null
            }
            maxDate={filters.inscricaoTerminaEm}
            maxDateMessage="A data não pode ser posterior à data final"
            minDateMessage="A data não pode ser anterior à data inicial"
            onChange={(value: any) =>
              setFilters({
                ...filters,
                inscricaoIniciaEm: format(value, "yyyy-MM-dd'T'HH:mm:ss"),
              })
            }
          />
        </Grid>
        <Grid item xs={1} className={middleLabel}>
          até
        </Grid>
        <Grid item xs className={pt0}>
          <DatePicker
            autoOk
            fullWidth
            size="small"
            disabled={loading}
            format="dd/MM/yyyy"
            animateYearScrolling
            inputVariant="outlined"
            value={
              filters.inscricaoTerminaEm
                ? format(
                    new Date(filters.inscricaoTerminaEm),
                    "yyyy-MM-dd'T'23:59:59",
                  )
                : null
            }
            minDate={filters.inscricaoIniciaEm}
            maxDateMessage="A data não pode ser posterior à data final"
            minDateMessage="A data não pode ser anterior à data inicial"
            onChange={(value: any) =>
              setFilters({
                ...filters,
                inscricaoTerminaEm: format(value, "yyyy-MM-dd'T'HH:mm:ss"),
              })
            }
          />
        </Grid>

        <Grid item xs={12} className={label}>
          Período de agendamento
        </Grid>
        <Grid item xs className={pt0}>
          <DatePicker
            autoOk
            fullWidth
            size="small"
            disabled={loading}
            format="dd/MM/yyyy"
            animateYearScrolling
            inputVariant="outlined"
            value={
              filters.agendamentoIniciaEm
                ? format(
                    new Date(filters.agendamentoIniciaEm),
                    "yyyy-MM-dd'T'00:00:01",
                  )
                : null
            }
            maxDate={filters.agendamentoTerminaEm}
            maxDateMessage="A data não pode ser posterior à data final"
            minDateMessage="A data não pode ser anterior à data inicial"
            onChange={(value: any) =>
              setFilters({
                ...filters,
                agendamentoIniciaEm: format(value, "yyyy-MM-dd'T'HH:mm:ss"),
              })
            }
          />
        </Grid>
        <Grid item xs={1} className={middleLabel}>
          até
        </Grid>
        <Grid item xs className={pt0}>
          <DatePicker
            autoOk
            fullWidth
            size="small"
            disabled={loading}
            format="dd/MM/yyyy"
            animateYearScrolling
            inputVariant="outlined"
            value={
              filters.agendamentoTerminaEm
                ? format(
                    new Date(filters.agendamentoTerminaEm),
                    "yyyy-MM-dd'T'23:59:59",
                  )
                : null
            }
            minDate={filters.agendamentoIniciaEm}
            maxDateMessage="A data não pode ser posterior à data final"
            minDateMessage="A data não pode ser anterior à data inicial"
            onChange={(value: any) =>
              setFilters({
                ...filters,
                agendamentoTerminaEm: format(value, "yyyy-MM-dd'T'HH:mm:ss"),
              })
            }
          />
        </Grid>

        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            disabled={loading}
            label={terms.termoLead}
            value={filters.identificacaoLead}
            onChange={({target}) => {
              setFilters({...filters, identificacaoLead: target.value});
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="E-mail"
            variant="outlined"
            disabled={loading}
            value={filters.emailLead}
            onChange={({target}) => {
              setFilters({...filters, emailLead: target.value});
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Telefone"
            variant="outlined"
            disabled={loading}
            value={filters.telefoneLead}
            onChange={({target}) => {
              setFilters({...filters, telefoneLead: target.value});
            }}
          />
        </Grid> */}
      </Grid>
      <Grid>
        <Box display="flex" flexDirection="column" marginLeft={1} marginTop={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filters.isVendaPerdida}
                disabled={loading}
                color="primary"
                onChange={() => {
                  setFilters((prevState) => ({
                    ...prevState,
                    isVendaPerdida: !prevState.isVendaPerdida,
                  }));
                }}
              />
            }
            label="Venda Perdida"
          />
        </Box>
      </Grid>
    </Drawer>
  );
};
