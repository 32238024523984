import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  MenuItem,
  ListSubheader,
  useTheme,
} from '@material-ui/core';
import {AutoComplete, Modal} from 'shared/components';
import {useTerm} from 'shared/hooks';
import {feedback} from 'shared/services/alertService';
import {IComplementary} from 'shared/services/api/ComplementaryService';
import {ICourse} from 'shared/services/api/CourseService';
import {
  EstablishmentService,
  IUnityEstablishment,
} from 'shared/services/api/EstablishmentService';
import {IGetOffer, OfferService} from 'shared/services/api/OfferService';
import {IShift} from 'shared/services/api/ShiftService';
import {errorResponse} from 'shared/utils/errorResponse';

interface IProps {
  campaignInfo: {
    nivelEstabelecimentoDono: number;
    idEstabelecimentoDono: string;
  };
  onClose(): void;
  shifts: IShift[];
  course: ICourse[];
  idCampanha: string;
  openModal: boolean;
  idRegister?: string;
  editModal?: boolean;
  isEditing?: boolean;
  defaultOffer: IGetOffer;
  setEditModal(value: boolean): void;
  complementaryOne: IComplementary[];
  complementaryTwo: IComplementary[];
}

export const ModalInclude: React.FC<IProps> = ({
  campaignInfo,
  course,
  shifts,
  onClose,
  openModal,
  idCampanha,
  idRegister,
  defaultOffer,
  setEditModal,
  complementaryOne,
  complementaryTwo,
  isEditing = true,
  editModal = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [offer, setOffer] = useState({
    ativo: true,
    idCampanha,
    idCurso: '',
    idCiclo: '',
    idTurno: '',
    idEstabelecimento: '',
    cnpjEstabelecimento: '',
    complementos1DaOferta: [] as string[],
    complementos2DaOferta: [] as string[],
  });

  const [establishments, setEstablishments] = useState<
    Omit<IUnityEstablishment[], 'unidades'>
  >([]);
  const [complementaryOneSelected, setComplementaryOneSelected] = useState<
    string[]
  >([]);
  const [complementaryTwoSelected, setComplementaryTwoSelected] = useState<
    string[]
  >([]);

  const {terms} = useTerm();
  const theme = useTheme();

  useEffect(() => {
    if (editModal) {
      const compOne = defaultOffer.complementos1DaOferta.map((i) => i.id);
      const compTwo = defaultOffer.complementos2DaOferta.map((i) => i.id);

      setComplementaryOneSelected(compOne);
      setComplementaryTwoSelected(compTwo);

      setOffer({
        ativo: defaultOffer.ativo,
        idCampanha: defaultOffer.idCampanha,
        idTurno: defaultOffer.idTurno,
        idCiclo: defaultOffer.idCiclo,
        idCurso: defaultOffer.idCurso,
        idEstabelecimento: defaultOffer.idEstabelecimento,
        complementos1DaOferta: compOne,
        complementos2DaOferta: compTwo,
        cnpjEstabelecimento: defaultOffer.estabelecimentoCnpj,
      });
      setTimeout(() => {
        setOffer(
          (state) => (state = {...state, idCiclo: defaultOffer.idCiclo}),
        );
      }, 250);
    }
  }, [editModal, defaultOffer]);

  const courses = useMemo(() => {
    if (
      course.filter((item) => item.id === defaultOffer.idCurso).length === 0
    ) {
      return [
        ...course,
        {
          id: defaultOffer.idCurso,
          nome: defaultOffer.nomeCurso,
          ativo: false,
          ciclos: [],
          usaCiclo: false,
        },
      ];
    }
    return course;
  }, [course, defaultOffer.idCurso, defaultOffer.nomeCurso]);

  const cycle = useMemo(() => {
    if (defaultOffer.idCiclo && defaultOffer.nomeCiclo) {
      return [
        {id: defaultOffer.idCiclo, nome: defaultOffer.nomeCiclo, ativo: false},
      ];
    }

    return offer.idCurso
      ? course
          .filter((i) => i.id === offer.idCurso)[0]
          .ciclos.filter((i) => i.ativo)
      : [];
  }, [defaultOffer, offer.idCurso, course]);

  const handleResetForm = useCallback(() => {
    setOffer({
      ativo: true,
      idCampanha: '',
      idCurso: '',
      idCiclo: '',
      idTurno: '',
      idEstabelecimento: '',
      cnpjEstabelecimento: '',
      complementos1DaOferta: [] as string[],
      complementos2DaOferta: [] as string[],
    });
    setSubmitted(false);
    setComplementaryOneSelected([]);
    setComplementaryTwoSelected([]);
    onClose();
  }, [onClose]);

  const handleGetEstablishment = useCallback(async () => {
    try {
      const response = await EstablishmentService.getEstablishments(idRegister);

      setEstablishments(response.estabelecimentos);
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
  }, [idRegister]);

  const handlePostOffer = useCallback(async () => {
    if (
      (cycle.length > 0 && !offer.idCiclo) ||
      !offer.idCurso ||
      !offer.idEstabelecimento
    ) {
      setSubmitted(true);

      return;
    }

    setLoading(true);
    try {
      await OfferService.postOffer({
        ativo: offer.ativo,
        idCampanha,
        idCiclo: offer.idCiclo,
        idCurso: offer.idCurso,
        idEstabelecimento: offer.idEstabelecimento,
        cnpjEstabelecimento: offer.cnpjEstabelecimento,
        idTurno: offer.idTurno === '' ? null : offer.idTurno,
        complementos1DaOferta: complementaryOneSelected,
        complementos2DaOferta: complementaryTwoSelected,
      });

      await handleResetForm();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setEditModal(false);
    setLoading(false);
  }, [
    cycle,
    offer,
    setEditModal,
    handleResetForm,
    complementaryOneSelected,
    complementaryTwoSelected,
    idCampanha,
  ]);

  const isEstablishmentDisabledVerifier = (
    idEstabelecimentoPai: string | null,
    id: string,
  ) => {
    if (
      idEstabelecimentoPai === null ||
      campaignInfo.nivelEstabelecimentoDono === 1
    ) {
      return false;
    }

    return !(id === campaignInfo.idEstabelecimentoDono);
  };

  const handlePutOffer = useCallback(async () => {
    setLoading(true);
    try {
      await OfferService.putOffer(defaultOffer.id, {
        ativo: offer.ativo,
        idCampanha,
        idCiclo: offer.idCiclo,
        idCurso: offer.idCurso,
        idEstabelecimento: offer.idEstabelecimento,
        cnpjEstabelecimento: offer.cnpjEstabelecimento,
        idTurno: offer.idTurno,
        complementos1DaOferta: complementaryOneSelected,
        complementos2DaOferta: complementaryTwoSelected,
      });

      await handleResetForm();
    } catch (error) {
      feedback(errorResponse(error), 'error');
    }
    setEditModal(false);
    setLoading(false);
  }, [
    offer,
    setEditModal,
    handleResetForm,
    complementaryOneSelected,
    complementaryTwoSelected,
    idCampanha,
    defaultOffer,
  ]);

  useEffect(() => {
    handleGetEstablishment();
  }, [handleGetEstablishment]);

  return (
    <Modal
      title={`${editModal ? 'Editar dados' : 'Dados'} oferta`}
      maxWidth="md"
      opened={openModal}
      labelSaveButton={`${editModal ? 'Salvar' : 'Incluir'}`}
      labelCloseButton="Cancelar"
      loading={loading}
      onClose={handleResetForm}
      onClick={editModal ? handlePutOffer : handlePostOffer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={offer.ativo}
                disabled={!isEditing}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setOffer(
                    (state) =>
                      (state = {...state, ativo: event.target.checked}),
                  );
                }}
              />
            }
            label="Ativo"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            error={!offer.idEstabelecimento && submitted}
            helperText={
              !offer.idEstabelecimento && submitted && 'Campo requerido'
            }
            value={offer.idEstabelecimento}
            disabled={editModal || !isEditing}
            label="Estabelecimento *"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setOffer(
                (state) =>
                  (state = {...state, idEstabelecimento: event.target.value}),
              );
            }}
            fullWidth
            select>
            <MenuItem value={0} disabled />
            {establishments?.map((item) => {
              if (item.idEstabelecimentoPai) {
                return (
                  <MenuItem
                    key={item.idEstabelecimento}
                    disabled={isEstablishmentDisabledVerifier(
                      item.idEstabelecimentoPai,
                      item.idEstabelecimento,
                    )}
                    value={item.idEstabelecimento}
                    onClick={() =>
                      setOffer((prev) => ({
                        ...prev,
                        cnpjEstabelecimento: item.cnpj,
                      }))
                    }
                    style={{paddingLeft: theme.spacing(3)}}>
                    {item.idComNome}
                  </MenuItem>
                );
              } else {
                return (
                  <ListSubheader key={item.idEstabelecimento}>
                    {item.idComNome}
                  </ListSubheader>
                );
              }
            })}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            error={!offer.idCurso && submitted}
            helperText={!offer.idCurso && submitted && 'Campo requerido'}
            value={offer.idCurso}
            disabled={editModal || !isEditing}
            label={`${terms.termoCurso} *`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setOffer(
                (state) =>
                  (state = {
                    ...state,
                    idCurso: event.target.value,
                    idCiclo: '',
                  }),
              );
            }}
            fullWidth
            select>
            <MenuItem value={0} disabled>
              Selecione um curso
            </MenuItem>
            {courses?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            error={cycle.length > 0 && !offer.idCiclo && submitted}
            helperText={
              cycle.length > 0 &&
              !offer.idCiclo &&
              submitted &&
              'Campo requerido'
            }
            value={offer.idCiclo}
            disabled={cycle.length === 0 || editModal || !isEditing}
            label={`${terms.termoCiclo} ${cycle.length > 0 ? '*' : ''}`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setOffer(
                (state) => (state = {...state, idCiclo: event.target.value}),
              );
            }}
            fullWidth
            select>
            <MenuItem value={0} disabled>
              Selecione um ciclo
            </MenuItem>
            {cycle?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            value={offer.idTurno}
            disabled={!isEditing}
            label="Turno"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setOffer(
                (state) => (state = {...state, idTurno: event.target.value}),
              );
            }}
            fullWidth
            select>
            <MenuItem value={0} disabled>
              Selecione um turno
            </MenuItem>
            {shifts?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {(terms.termoComplementar2 || terms.termoComplementar1) && (
          <Grid item xs={12}>
            <Typography variant="h6">Informações complementares</Typography>
          </Grid>
        )}

        {terms.termoComplementar1 && (
          <Grid item xs={12}>
            <AutoComplete
              multiple
              disabled={!isEditing}
              disableCloseOnSelect
              options={complementaryOne}
              getOptionLabel={(option) => option.nome}
              getOptionSelected={(option, value) => option.id === value.id}
              filterSelectedOptions
              defaultValue={defaultOffer.complementos1DaOferta}
              onChange={(_, value) =>
                setComplementaryOneSelected(
                  value.map((i: IComplementary) => i.id),
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={terms.termoComplementar1}
                />
              )}
            />
          </Grid>
        )}

        {terms.termoComplementar2 && (
          <Grid item xs={12}>
            <AutoComplete
              multiple
              disabled={!isEditing}
              disableCloseOnSelect
              options={complementaryTwo}
              getOptionLabel={(option) => option.nome}
              getOptionSelected={(option, value) => option.id === value.id}
              filterSelectedOptions
              defaultValue={defaultOffer.complementos2DaOferta}
              onChange={(_, value) =>
                setComplementaryTwoSelected(
                  value.map((i: IComplementary) => i.id),
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={terms.termoComplementar2}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};
