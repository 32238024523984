import React, {createContext, useState} from 'react';
import {ILeadCallsFilters} from 'shared/services/api/LeadService';

interface ILeadFilterContextData {
  appliedFilters: ILeadCallsFilters;
  setAppliedFilters: (filters: ILeadCallsFilters) => void;
}

export const LeadFilterContext = createContext<ILeadFilterContextData>(
  {} as ILeadFilterContextData,
);

export const LeadFilterProvider: React.FC = ({children}) => {
  const [appliedFilters, setAppliedFilters] = useState<ILeadCallsFilters>({
    idEstabelecimento: 0,
    idCampanha: 0,
    idSituacaoDoLead: 0,
    idCurso: 0,
    idCiclo: 0,
    idUsuarioAtendente: 0,
    idUsuarioEntrevistador: 0,
    inscricaoIniciaEm: null,
    inscricaoTerminaEm: null,
    agendamentoIniciaEm: null,
    agendamentoTerminaEm: null,
    identificacaoLead: null,
    emailLead: null,
    telefoneLead: null,
    isVendaPerdida: false,
  } as ILeadCallsFilters);

  return (
    <LeadFilterContext.Provider value={{appliedFilters, setAppliedFilters}}>
      {children}
    </LeadFilterContext.Provider>
  );
};
