import {createStyles, makeStyles, Theme} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -theme.spacing(1.5),
      marginLeft: -theme.spacing(1.5),
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    headAct: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 0,
      background: '#e0e0e0',
    },
    headLead: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 130,
      background: '#e0e0e0',
      borderRight: '1px solid rgba(255, 255, 255)',
    },
    bodyAct: {
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 0,
      background: '#FFF',
      pointerEvents: 'auto',
    },
    bodyLead: {
      display: 'flex',
      paddingTop: 12,
      whiteSpace: 'nowrap',
      position: 'sticky',
      left: 130,
      background: '#FFF',
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
    baseboard: {
      marginTop: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: theme.spacing(2),
    },
    label: {
      color: '#777',
      paddingBottom: '0 !important',
    },
    middleLabel: {
      color: '#777',
      padding: '8px 5px 12px !important',
    },
    pt0: {
      paddingTop: '0 !important',
    },
    rowDisabled: {
      color: '#777',
      whiteSpace: 'nowrap',
    },
    displayNone: {
      display: 'none',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    select: {
      minWidth: '30px',
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  }),
);
